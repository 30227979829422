import React from 'react'

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const ChevronRight = ({ width, height, color }: Props) => {
    return (
        <svg width={width || "17"} height={height || "16"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_155_598)">
                <path d="M8.49992 14.6667C12.1818 14.6667 15.1666 11.6819 15.1666 7.99999C15.1666 4.3181 12.1818 1.33333 8.49992 1.33333C4.81802 1.33333 1.83325 4.3181 1.83325 7.99999C1.83325 11.6819 4.81802 14.6667 8.49992 14.6667Z" stroke={color || "#31280C"} stroke-width="2" />
                <path d="M7.5 5.33331L10.1667 7.99998L7.5 10.6666" stroke={color || "#31280C"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_155_598">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default ChevronRight